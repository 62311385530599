import React from "react";
import { useTranslation } from "react-i18next";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/captioned.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
const AutoplaySlider = withAutoplay(AwesomeSlider);

function SolutionsSection() {
  const { t } = useTranslation();

  const serviceData = [
    {
      id: 1,
      iconClass: "fa fa-parking",
      text: t("Parking sistemi"),
    },
    {
      id: 2,
      iconClass: "fa fa-podcast",
      text: t("Sistemi za detekciju slobodnih mjesta"),
    },
    {
      id: 3,
      iconClass: "fa fa-ticket-alt",
      text: t("Parkomati za otvorene parkinge"),
    },
    {
      id: 4,
      iconClass: "fas fa-tasks",
      text: t("Specifična rješenja po zahtjevu"),
    },
  ];

  return (
    <section className="section service-area overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-5 order-1 order-lg-1">
            <div className="service-text pt-4 pt-lg-0">
              <h2 className="mb-4">{t("Sistemska rješenja")}</h2>
              <ul className="service-list">
                {serviceData.map((item, idx) => {
                  return (
                    <div key={`so_${idx}`}>
                      <li className="single-service media py-2">
                        <div className="service-icon pr-4">
                          <span>
                            <i
                              className={item.iconClass}
                              style={{
                                width: idx === 0 ? "2rem" : "1.5rem",
                                height: "3.2rem",
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="service-text media-body"
                          style={{ alignSelf: "center" }}
                        >
                          <p  style={{fontSize: '16px' }}>{item.text}</p>
                        </div>
                      </li>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="col-12 col-lg-7 order-1 order-lg-2 d-lg-block parking-slider">
            <div>
              <AutoplaySlider
                animation="cubeAnimation"
                play={true}
                cancelOnInteraction={true}
                interval={3000}
              >
                <div data-src="/images/image26.png" alt="a" />
                <div data-src="/images/image30.png" alt="a" />
                <div data-src="/images/image21.png" alt="a" />
                <div data-src="/images/image17.png" alt="a" />
              </AutoplaySlider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SolutionsSection;
