import React from "react";
import PictureRightContent from "./PictureRightContent";
import PictureLeftContent from "./PictureLeftContent";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function ParkingContent() {
  const { t } = useTranslation();
  const location = useLocation();

  const faqDataThree = [
    {
      id: 1,
      title: t("Connect serija"),
      data1: {
        title: "Connect IN/OUT",
        height: "32rem",
        image: "images/connect/conect-in.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.1") korisnički interfejs sa mnogo funkcionalnosti i opcija. kao što su višejezično biranje te prebooking servisi sa rezervacijama za izdavanje parking tiketa.`,
          },
          {
            id: 2,
            text: "TFT display (18”) za prikaz medija i reklama.",
          },
          {
            id: 3,
            text: "Visokokvalitetn sjani panel sa led pozadinskim osvjetljenjem u okviru Plexiglas XT prstena.",
          },
          {
            id: 4,
            text: "Modularan spremnik do 10.000 parking tiketa sa konfigurabilan upozorenjem potrošenosti spremnika.",
          },
          {
            id: 5,
            text: "SBC sa Linux operativnim sistemom sa pasivnim ili aktivnim hladnjakom.",
          },
          {
            id: 6,
            text: "Integrisan VoiP ili intecom.",
          },
          {
            id: 7,
            text: "Ethernet I/O interfejs.",
          },
          {
            id: 8,
            text: "Robusno i otporno kućište na vanjske uslove.",
          },
        ],
      },
      data2: {
        title: "Opcionalno",
        image: "images/connect/front-in.jfif",

        items: [
          {
            id: 1,
            text: `Mogućnost reklamnih poruka na primarnom displeyu uređaja.`,
          },
          {
            id: 2,
            text: "Mogućnost ugradnje POS bankovnih čitača kartica na Ulazno/Izlaznim uređajima.",
          },
          {
            id: 3,
            text: "Mogučnost ugradnje Acess Control Prox kartica, ISO 15963 i ostalih RFID sistema sa kratkim, srednjim ili dugim dometom čitanja tokena za stalne korisnike(pretplatnike).",
          },
          {
            id: 4,
            text: "QR code, Barcode ili tehnologija magnetnog zapisa na tiketima.",
          },
          {
            id: 5,
            text: "LPR kamere za prepoznavanje tablica.",
          },
          {
            id: 6,
            text: "Audio upravljačke komande za vozače.",
          },
          {
            id: 7,
            text: "VoiP- SIP omogućene telefonske stanice prilagodljive za razne zahtjeve.",
          },
          {
            id: 8,
            text: "Prebooking sa PIN ili Barcode identifikacijom.",
          },
          {
            id: 9,
            text: "Termostatski kontrolisano grijanje ili hlađenje.",
          },
        ],
      },
      data3: {
        title: "Pay Cash & Card automat",
        image: "images/connect/connect-pay.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.4") korisnički interfejs sa mnogo funkcionalnosti i opcija, kao što su navigacija korisnika,grafička uputstva, plaćanje putem unosa tablice vozila, višejezično biranje.`,
          },
          {
            id: 2,
            text: "Media display (18.5”) za prikaz medija i reklama",
          },
          {
            id: 3,
            text: "LED iluminacija okvira, sa mogućnošću mjenjanja boja.",
          },
          {
            id: 4,
            text: "Procesiranje tiketa sa barcode, QR code ili tehnologijom magnetnog zapisa.",
          },
          {
            id: 5,
            text: "Procesiranje kovanica.",
          },
          {
            id: 6,
            text: "Procesiranje novčanica.",
          },
          {
            id: 7,
            text: "Mogućnost vraćanja kusura u novčanicama.",
          },
          {
            id: 8,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 9,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 10,
            text: "Termalni printer za račune.",
          },
          {
            id: 11,
            text: "UV zaštitni plexiglas.",
          },
          {
            id: 12,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },
      data4: {
        title: "Pay Coinless automat",
        image: "images/connect/coinless.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.4") korisnički interfejs sa mnogo funkcionalnosti i opcija, kao što su navigacija korisnika,grafička uputstva, plaćanje putem unosa tablice vozila, višejezično biranje.`,
          },
          {
            id: 2,
            text: "Procesiranje novčanica.",
          },
          {
            id: 3,
            text: "Mogućnost vraćanja kusura u nočanicama.",
          },
          {
            id: 4,
            text: "LED iluminacija okvira, sa mogućnošću mjenjanja boja.",
          },
          {
            id: 5,
            text: "Procesiranje tiketa sa barcode, QR code ili tehnologijom magnetnog zapisa.",
          },
          {
            id: 6,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 7,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 8,
            text: "Termalni printer za račune.",
          },
          {
            id: 9,
            text: "UV zaštitni plexiglas.",
          },
          {
            id: 10,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },
      data5: {
        title: "Pay by Card automat",
        image: "images/connect/card.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.4") korisnički interfejs sa mnogo funkcionalnosti i opcija, kao što su navigacija korisnika,grafička uputstva, plaćanje putem unosa tablice vozila, višejezično biranje.`,
          },
          {
            id: 2,
            text: "LED iluminacija okvira, sa mogućnošću mjenjanja boja.",
          },
          {
            id: 3,
            text: "Procesiranje tiketa sa barcode, QR code ili tehnologijom magnetnog zapisa.",
          },
          {
            id: 4,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 5,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 6,
            text: "Termalni printer za račune.",
          },
          {
            id: 7,
            text: "UV zaštitni plexiglas.",
          },
          {
            id: 8,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },
      data6: {
        title: "Manuelna naplata",
        image: "images/connect/manual.jfif",
        items: [
          {
            id: 1,
            text: `TFT Touch color display (15”).`,
          },
          {
            id: 2,
            text: "Barcode,QR code ili čitač magnetnih kartica.",
          },
          {
            id: 3,
            text: "Naplata jednokratnih tiketa.",
          },
          {
            id: 4,
            text: "Procesiranje QR code kupona.",
          },
          {
            id: 5,
            text: "Prikaz računa na ekranu.",
          },
          {
            id: 6,
            text: "Eksterni ekran za prikaz cijene kupcu.",
          },
          {
            id: 7,
            text: "Kodiranje specijalnih tarifa.",
          },
          {
            id: 8,
            text: "Naplata hotel tiketa, kongress tiketa.",
          },
          {
            id: 9,
            text: "POS printer za štampu izvještaja.",
          },
        ],
      },
      data7: {
        title: "Gate rampa 600",
        image: "images/connect/ramp.jfif",
        items: [
          {
            id: 1,
            text: `Najsavremenija izvedba elektro motora u svojoj klasi  sa visokom izdržljivošću i efikasnom potrošnjom.`,
          },
          {
            id: 2,
            text: "TFT display (18”) za prikaz medija i reklama.",
          },
          {
            id: 3,
            text: "Brzina otvaranja(1,3s)/zatvaranja(1,5s).",
          },
          {
            id: 4,
            text: "Boost opcija za prepoznavanje SUV/teretnih vozila  na petlji.",
          },
          {
            id: 5,
            text: "Ethernet I/O kontroler sa mnoštvom funkcionalnosti.",
          },
          {
            id: 6,
            text: "Pojačana konstrukcija koja štiti od udara.",
          },
        ],
      },
      data8: {
        title: "E-Charging punionica",
        image: "images/connect/e-charging.jfif",
        items: [
          {
            id: 1,
            text: `Električna punionica sa Barcode, QR code ili čitačem magnetnih kartica.`,
          },
          {
            id: 2,
            text: "Mogućnost punjenja 2 automobila sa jedne stanice.",
          },
          {
            id: 3,
            text: "Mogućnost naplate i tarifiranja korištenja punionice u sklopu integrisanog parking sistema.",
          },
          {
            id: 4,
            text: "Standardi  IEC 61851-21-2:2018 (EV-ready, ZV-ready, DIN EN 61439 (VDE 0660-600), EN IEC 61851, EN 61439.",
          },
        ],
      },
      data9: {
        title: "Door Controller",
        image: "images/connect/door.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.1") korisnički interfejs sa mnogo funkcionalnosti i opcija.`,
          },
          {
            id: 2,
            text: "Kontrola vrata od objekata ili garažnih vrata.",
          },
          {
            id: 3,
            text: "Barcode, QR code i RFID čitač kartica integrisanih u sistemu parkiranja.",
          },
          {
            id: 4,
            text: "Višejezično biranje.",
          },
          {
            id: 5,
            text: "Unos tablica vozila preko tastature ekrana.",
          },
          {
            id: 6,
            text: "Ethernet I/O interfejs modul.",
          },
        ],
      },
    },
  ];

  const faqDataTwo = [
    {
      id: 1,
      title: t("Abacus serija"),
    data1: {
        title: "Abacus IN/OUT",
        height: "32rem",
        image: "images/abacus/entrant-device.jpg",
        items: [
          {
            id: 1,
            text: `LED karatkerni display za ispis korisničkih upustava.`,
          },
          {
            id: 2,
            text: "Visokokvalitetn sjani panel sa led pozadinskim osvjetljenjem.",
          },
          {
            id: 3,
            text: "Modularan spremnik do 10.000 parking tiketa sa konfigurabilan upozorenjem potrošenosti spremnika.",
          },
          {
            id: 4,
            text: "TCC sa Linux operativnim sistemom sa pasivnim ili aktivnim hladnjakom.",
          },
          {
            id: 5,
            text: "Integrisan VoiP ili intecom.",
          },
          {
            id: 6,
            text: "Ethernet I/O interfejs.",
          },
          {
            id: 7,
            text: "Robusno i otporno kućište na vanjske uslove.",
          },
        ],
      },
      data2: {
        title: "Opcionalno",
        image: "images/abacus/entrant-device-optional.jpg",

        items: [
          {
            id: 1,
            text: `Mogućnost ugradnje TFT display (7”).`,
          },
          {
            id: 2,
            text: "Mogućnost ugradnje POS bankovnih čitača kartica ns Izlaznim uređajima.",
          },
          {
            id: 3,
            text: "Mogučnost ugradnje Acess Control Prox kartica, ISO 15963 i ostalih RFID sistema sa kratkim, srednjim ili dugim dometom čitanja tokena za stalne korisnike(pretplatnike).",
          },
          {
            id: 4,
            text: "QR code, Barcode ili tehnologija magnetnog zapisa na tiketima.",
          },
          {
            id: 5,
            text: "LPR kamere za prepoznavanje tablica.",
          },
          {
            id: 6,
            text: "Audio upravljačke komande za vozače.",
          },
          {
            id: 7,
            text: "VoiP- SIP omogućene telefonske stanice prilagodljive za razne zahtjeve.",
          },
          {
            id: 8,
            text: "Prebooking sa PIN ili Barcode identifikacijom.",
          },
          {
            id: 9,
            text: "Termostatski kontrolisano grijanje ili hlađenje.",
          },
        ],
      },
      data3: {
        title: "Pay Cash & Card automat",
        image: "images/connect/connect-pay.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.4") korisnički interfejs sa mnogo funkcionalnosti i opcija, kao što su navigacija korisnika,grafička uputstva, plaćanje putem unosa tablice vozila, višejezično biranje.`,
          },
          {
            id: 2,
            text: "Media display (18.5”) za prikaz medija i reklama",
          },
          {
            id: 3,
            text: "LED iluminacija okvira, sa mogućnošću mjenjanja boja.",
          },
          {
            id: 4,
            text: "Procesiranje tiketa sa barcode, QR code ili tehnologijom magnetnog zapisa.",
          },
          {
            id: 5,
            text: "Procesiranje kovanica.",
          },
          {
            id: 6,
            text: "Procesiranje novčanica.",
          },
          {
            id: 7,
            text: "Mogućnost vraćanja kusura u novčanicama.",
          },
          {
            id: 8,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 9,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 10,
            text: "Termalni printer za račune.",
          },
          {
            id: 11,
            text: "UV zaštitni plexiglas.",
          },
          {
            id: 12,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },
      data4: {
        title: "Pay Coinless automat",
        image: "images/connect/coinless.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.4") korisnički interfejs sa mnogo funkcionalnosti i opcija, kao što su navigacija korisnika,grafička uputstva, plaćanje putem unosa tablice vozila, višejezično biranje.`,
          },
          {
            id: 2,
            text: "Procesiranje novčanica.",
          },
          {
            id: 3,
            text: "Mogućnost vraćanja kusura u nočanicama.",
          },
          {
            id: 4,
            text: "LED iluminacija okvira, sa mogućnošću mjenjanja boja.",
          },
          {
            id: 5,
            text: "Procesiranje tiketa sa barcode, QR code ili tehnologijom magnetnog zapisa.",
          },
          {
            id: 6,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 7,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 8,
            text: "Termalni printer za račune.",
          },
          {
            id: 9,
            text: "UV zaštitni plexiglas.",
          },
          {
            id: 10,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },
      data5: {
        title: "Pay by Card automat",
        image: "images/connect/card.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.4") korisnički interfejs sa mnogo funkcionalnosti i opcija, kao što su navigacija korisnika,grafička uputstva, plaćanje putem unosa tablice vozila, višejezično biranje.`,
          },
          {
            id: 2,
            text: "LED iluminacija okvira, sa mogućnošću mjenjanja boja.",
          },
          {
            id: 3,
            text: "Procesiranje tiketa sa barcode, QR code ili tehnologijom magnetnog zapisa.",
          },
          {
            id: 4,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 5,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 6,
            text: "Termalni printer za račune.",
          },
          {
            id: 7,
            text: "UV zaštitni plexiglas.",
          },
          {
            id: 8,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },
      data6: {
        title: "Manuelna naplata",
        image: "images/connect/manual.jfif",
        items: [
          {
            id: 1,
            text: `TFT Touch color display (15”).`,
          },
          {
            id: 2,
            text: "Barcode,QR code ili čitač magnetnih kartica.",
          },
          {
            id: 3,
            text: "Naplata jednokratnih tiketa.",
          },
          {
            id: 4,
            text: "Procesiranje QR code kupona.",
          },
          {
            id: 5,
            text: "Prikaz računa na ekranu.",
          },
          {
            id: 6,
            text: "Eksterni ekran za prikaz cijene kupcu.",
          },
          {
            id: 7,
            text: "Kodiranje specijalnih tarifa.",
          },
          {
            id: 8,
            text: "Naplata hotel tiketa, kongress tiketa.",
          },
          {
            id: 9,
            text: "POS printer za štampu izvještaja.",
          },
        ],
      },
      data7: {
        title: "Gate rampa 600",
        image: "images/connect/ramp.jfif",
        items: [
          {
            id: 1,
            text: `Najsavremenija izvedba elektro motora u svojoj klasi  sa visokom izdržljivošću i efikasnom potrošnjom.`,
          },
          {
            id: 2,
            text: "TFT display (18”) za prikaz medija i reklama.",
          },
          {
            id: 3,
            text: "Brzina otvaranja(1,3s)/zatvaranja(1,5s).",
          },
          {
            id: 4,
            text: "Boost opcija za prepoznavanje SUV/teretnih vozila  na petlji.",
          },
          {
            id: 5,
            text: "Ethernet I/O kontroler sa mnoštvom funkcionalnosti.",
          },
          {
            id: 6,
            text: "Pojačana konstrukcija koja štiti od udara.",
          },
        ],
      },
    },
  ];

  const faqDataOne = [
    {
      id: 1,
      title: t("Slimpark serija"),
    data1: {
        title: "Slimpark IN/OUT",
        height: "32rem",
        image: "images/slimpark/entrant-device.jfif",
        items: [
          {
            id: 1,
            text: `LED karatkerni display za ispis korisničkih upustava.`,
          },
          {
            id: 2,
            text: "VQR code, Barcode tehnologija na tiketima.",
          },
          {
            id: 3,
            text: "Modularan spremnik do 10.000 parking tiketa sa konfigurabilan upozorenjem potrošenosti spremnika.",
          },
          {
            id: 4,
            text: "TCC sa Linux operativnim sistemom sa pasivnim ili aktivnim hladnjakom.",
          },
          {
            id: 5,
            text: "Integrisan VoiP ili intecom.",
          },
          {
            id: 6,
            text: "Ethernet I/O interfejs.",
          },
          {
            id: 7,
            text: "Robusno i otporno kućište na vanjske uslove.",
          },
        ],
      },
      data2: {
        title: "Opcionalno",
        image: "images/slimpark/entrant-device-optional.jfif",

        items: [
          {
            id: 1,
            text: "Mogučnost ugradnje Acess Control Prox kartica, ISO 15963 i ostalih RFID sistema sa kratkim, srednjim ili dugim dometom čitanja tokena za stalne korisnike(pretplatnike).",
          },

          {
            id: 2,
            text: "LPR kamere za prepoznavanje tablica.",
          },
          {
            id: 3,
            text: "Audio upravljačke komande za vozače.",
          },
          {
            id: 4,
            text: "VoiP- SIP omogućene telefonske stanice prilagodljive za razne zahtjeve.",
          },

          {
            id: 5,
            text: "Termostatski kontrolisano grijanje ili hlađenje.",
          },
        ],
      },
      data3: {
        title: "Slimpark Pay Cash & Card automat",
        image: "images/slimpark/slimpark.jfif",
        items: [
          {
            id: 1,
            text: `TFT touch displey(10.4") korisnički interfejs sa mnogo funkcionalnosti i opcija, kao što su navigacija korisnika,grafička uputstva, plaćanje putem unosa tablice vozila, višejezično biranje.`,
          },
          {
            id: 2,
            text: "Media display (18.5”) za prikaz medija i reklama",
          },
          {
            id: 3,
            text: "LED iluminacija okvira, sa mogućnošću mjenjanja boja.",
          },
          {
            id: 4,
            text: "Procesiranje tiketa sa barcode, QR code ili tehnologijom magnetnog zapisa.",
          },
          {
            id: 5,
            text: "Procesiranje kovanica.",
          },
          {
            id: 6,
            text: "Procesiranje novčanica.",
          },
          {
            id: 7,
            text: "Mogućnost vraćanja kusura u novčanicama.",
          },
          {
            id: 8,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 9,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 10,
            text: "Termalni printer za račune.",
          },
          {
            id: 11,
            text: "UV zaštitni plexiglas.",
          },
          {
            id: 12,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },
      data4: {
        title: "Slimpark Slim Pay Cash automat",
        image: "images/slimpark/collenction-machine.jfif",
        items: [
          {
            id: 1,
            text: `LED karakterni display ili TFT (10.1”) display za prikaz grafičkih uputa i navigaciju korisnika te višejezično biranje.`,
          },
          {
            id: 2,
            text: "Procesiranje tiketa sa barcode ili QR code tehnologijom.",
          },
          {
            id: 3,
            text: "Procesiranje kovanica.",
          },
          {
            id: 4,
            text: "Procesiranje novčanica.",
          },
          {
            id: 5,
            text: "PMogućnost vraćanja kusura u nočanicama.",
          },
          {
            id: 6,
            text: "NFC plaćanja /Apple play /Google pay.",
          },
          {
            id: 7,
            text: "Plaćanje bankovnim karticama .",
          },
          {
            id: 8,
            text: "Termalni printer za račune.",
          },
          {
            id: 9,
            text: "Robusno kućište za zaštitu od vanjskih uticaja.",
          },
          {
            id: 10,
            text: "Intercom ili VoIP uređaj za komunikaciju sa centrom.",
          },
        ],
      },

      data6: {
        title: "Manuelna naplata",
        image: "images/connect/manual.jfif",
        items: [
          {
            id: 1,
            text: `TFT Touch color display (15”).`,
          },
          {
            id: 2,
            text: "Barcode,QR code ili čitač magnetnih kartica.",
          },
          {
            id: 3,
            text: "Naplata jednokratnih tiketa.",
          },
          {
            id: 4,
            text: "Procesiranje QR code kupona.",
          },
          {
            id: 5,
            text: "Prikaz računa na ekranu.",
          },
          {
            id: 6,
            text: "Eksterni ekran za prikaz cijene kupcu.",
          },
          {
            id: 7,
            text: "Kodiranje specijalnih tarifa.",
          },
          {
            id: 8,
            text: "Naplata hotel tiketa, kongress tiketa.",
          },
          {
            id: 9,
            text: "POS printer za štampu izvještaja.",
          },
        ],
      },
      data7: {
        title: "Gate rampa 600",
        image: "images/connect/ramp.jfif",
        items: [
          {
            id: 1,
            text: `Najsavremenija izvedba elektro motora u svojoj klasi  sa visokom izdržljivošću i efikasnom potrošnjom.`,
          },
          {
            id: 2,
            text: "TFT display (18”) za prikaz medija i reklama.",
          },
          {
            id: 3,
            text: "Brzina otvaranja(1,3s)/zatvaranja(1,5s).",
          },
          {
            id: 4,
            text: "Boost opcija za prepoznavanje SUV/teretnih vozila  na petlji.",
          },
          {
            id: 5,
            text: "Ethernet I/O kontroler sa mnoštvom funkcionalnosti.",
          },
          {
            id: 6,
            text: "Pojačana konstrukcija koja štiti od udara.",
          },
        ],
      },
    },
  ];

  return (
    <section className=" faq-area ptb_100" style={{paddingTop: 0, paddingBottom: 300}}>
      <div className="container">
        {/* <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-7">
            <div className="text-center">
              <h2>{headingTwo}</h2>
            </div>
          </div>
        </div> */}
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="blog-details"></div>

            <div className="faq-content">
              <div className="accordion" id="sApp-accordion">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-12 col-lg-12">
                    {faqDataThree.map((item, idx) => {
                      return (
                        <div
                          key={`ft_${idx}`}
                          className="card border-top-0 border-left-0 border-right-0 border-bottom"
                        >
                          <div
                            className="card-header bg-inherit border-0 p-0"
                            id={`ps-${idx}`}
                          >
                            <h2 className="mb-0">
                              <button
                                className="btn px-0 py-3"
                                type="button"
                                data-toggle="collapse"
                                style={{ fontSize: "26px" }}
                                data-target={"#f_" + idx}
                              >
                                {item.title}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={"f_" + idx}
                            className={`collapse ${
                              (location.hash === "#ps" || location.hash === "#ps-" + idx) ? "show" : ""
                            }`}
                          >
                            <div className="card-body px-0 py-3">
                              {item.content}
                              <PictureLeftContent
                                img={item.data1.image}
                                title={item.data1.title}
                                items={item.data1.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data2.image}
                                title={item.data2.title}
                                items={item.data2.items}
                                height={item.data1.height}
                              />
                              <PictureLeftContent
                                img={item.data3.image}
                                title={item.data3.title}
                                items={item.data3.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data4.image}
                                title={item.data4.title}
                                items={item.data4.items}
                                height={item.data1.height}
                              />
                              <PictureLeftContent
                                img={item.data5.image}
                                title={item.data5.title}
                                items={item.data5.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data6.image}
                                title={item.data6.title}
                                items={item.data6.items}
                              />
                              <PictureLeftContent
                                img={item.data7.image}
                                title={item.data7.title}
                                items={item.data7.items}
                              />
                              <PictureRightContent
                                img={item.data8.image}
                                title={item.data8.title}
                                items={item.data8.items}
                              />
                              <PictureLeftContent
                                img={item.data9.image}
                                title={item.data9.title}
                                items={item.data9.items}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {faqDataTwo.map((item, idx) => {
                      return (
                        <div
                          key={`ft_${idx + 1}`}
                          className="card border-top-0 border-left-0 border-right-0 border-bottom"
                        >
                          <div
                            className="card-header bg-inherit border-0 p-0"
                            id={`ps-${idx + 1}`}
                          >
                            <h2 className="mb-0">
                              <button
                                className="btn px-0 py-3"
                                type="button"
                                data-toggle="collapse"
                                style={{ fontSize: "26px" }}
                                data-target={"#f_" + idx + 1}
                              >
                                {item.title}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={"f_" + idx + 1}
                            className={`collapse ${
                              (location.hash === "#ps" ||location.hash === "#ps-" + (idx + 1)) ? "show" : ""
                            }`}
                          >
                            <div className="card-body px-0 py-3">
                              {item.content}
                              <PictureLeftContent
                                img={item.data1.image}
                                title={item.data1.title}
                                items={item.data1.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data2.image}
                                title={item.data2.title}
                                items={item.data2.items}
                                height={item.data1.height}
                              />
                              <PictureLeftContent
                                img={item.data3.image}
                                title={item.data3.title}
                                items={item.data3.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data4.image}
                                title={item.data4.title}
                                items={item.data4.items}
                                height={item.data1.height}
                              />
                              <PictureLeftContent
                                img={item.data5.image}
                                title={item.data5.title}
                                items={item.data5.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data6.image}
                                title={item.data6.title}
                                items={item.data6.items}
                              />
                              <PictureLeftContent
                                img={item.data7.image}
                                title={item.data7.title}
                                items={item.data7.items}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {faqDataOne.map((item, idx) => {
                      return (
                        <div
                          key={`ft_${idx + 2}`}
                          className="card border-top-0 border-left-0 border-right-0 border-bottom"
                        >
                          <div
                            className="card-header bg-inherit border-0 p-0"
                            id={`ps-${idx + 2}`}
                          >
                            <h2 className="mb-0">
                              <button
                                className="btn px-0 py-3"
                                type="button"
                                data-toggle="collapse"
                                style={{ fontSize: "26px" }}
                                data-target={"#f_" + idx + 2}
                              >
                                {item.title}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={"f_" + idx + 2}
                            className={`collapse ${
                              (location.hash === "#ps" || location.hash === "#ps-" + (idx + 2)) ? "show" : ""
                            }`}
                          >
                            <div className="card-body px-0 py-3">
                              {item.content}
                              <PictureLeftContent
                                img={item.data1.image}
                                title={item.data1.title}
                                items={item.data1.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data2.image}
                                title={item.data2.title}
                                items={item.data2.items}
                                height={item.data1.height}
                              />
                              <PictureLeftContent
                                img={item.data3.image}
                                title={item.data3.title}
                                items={item.data3.items}
                                height={item.data1.height}
                              />
                              <PictureRightContent
                                img={item.data4.image}
                                title={item.data4.title}
                                items={item.data4.items}
                                height={item.data1.height}
                              />
              
                              <PictureLeftContent
                                img={item.data6.image}
                                title={item.data6.title}
                                items={item.data6.items}
                              />
                              <PictureRightContent
                                img={item.data7.image}
                                title={item.data7.title}
                                items={item.data7.items}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ParkingContent;
