import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-carousel-minimal";

const initData = {
  headingText:
    "Ukoliko želite potpun nadzor nad svim pojedinačnim parking mjestima, nudimo vam više rješenja uz pomoć kojih ćete imati poptun uvid i analizu svih zauzetih i slobodnih mjesta.",
  headingTexttwo:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
};

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

function DetectionSection() {
  const { t } = useTranslation();

const data = [
  {
    id: "1",
    image: "/images/detection/image14.jpg",
    caption: t("Senzor sa integrisanim LED-om"),
  },
  {
    id: "2",
    image: "/images/detection/image4.jpg",
    caption: t("Senzor za dijagonalnu detekciju"),
  },
  {
    id: "3",
    image: "/images/detection/image8.png",
    caption: t("Wireless podni senzori"),
  },
  {
    id: "4",
    image: "/images/detection/image2.png",
    caption: t("Kamere za nadzor individualnih parking karakteristika"),
  },
  {
    id: "5",
    image: "/images/detection/image24.jpg",
    caption: t("Senzor za detekciju sa externim LED"),
  },
  {
    id: "6",
    image: "/images/detection/image12.jpg",
    caption: t("Senzor za nadozor sa dvije kamere"),
  },
  {
    id: "7",
    image: "/images/detection/image21.png",
    caption: t("Kamere za nadzor neoznačenih parking mjesta")
  },
];
  return (
    <section className="section branding-area ptb_100 " id="detection">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className=" text-center">
              <h2>{t("Sistemi za detekciju parking mjesta")}</h2>
              <p className="d-none d-sm-block mt-4">Ukoliko želite potpun nadzor nad svim pojedinačnim parking mjestima, nudimo vam rješenja od{" "}
                <a
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                  href="https://multiguide.com/"
                  rel="noopener noreferrer"
                >
                  MultiGuide
                </a>{" "} uz pomoć kojih ćete imati poptun uvid i analizu svih zauzetih i slobodnih mjesta.</p>
              <p className="d-block d-sm-none mt-4">
                {initData.headingTexttwo}
              </p>
            </div>
          </div>
        </div>
        <div
          className="row custom-corousel"
          style={{ flexDirection: "column" }}
        >
          <Carousel
            data={data}
            time={3000}
            height="270px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={false}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="none"
            slideImageFit="cover"
            thumbnails={true}
            showNavBtn={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxHeight: "500px",
              margin: "40px auto",
              width: "75%",
            }}
          />
        </div>
        <div
          style={{
            justifyContent: "center",
            margin: "0 auto",
            display: "flex",
          }}
        >
          <a href="/detekcija" className="btn btn-bordered">
            {t("Saznaj više")}
          </a>
        </div>
      </div>
    </section>
  );
}

export default DetectionSection;
