import React from "react";
import { useTranslation } from "react-i18next";

const discoverData = [
  {
    id: 1,
    iconClass: "fas fa-check",
    text: "TFT touch display(10.1'') korisnički interfejs sa mnogo funkcionalnosti i opcija. kao što su višejezično biranje te prebooking servisi sa rezervacijama za izdavanje parking tiketa.",
  },
  {
    id: 2,
    iconClass: "fas fa-check",
    text: "QR code, Barcode ili tehnologija magnetnog zapisa na tiketima.",
  },

  {
    id: 3,
    iconClass: "fas fa-check",
    text: "Acess Control Prox kartice, ISO 15963 i ostali RFID sistemi sa kratkim, srednjim ili dugim dometom čitanja tokena za stalne korisnike(pretplatnike).",
  },
  {
    id: 4,
    iconClass: "fas fa-check",
    text: "LPR kamere za prepoznavanje tablica.",
  },
  {
    id: 5,
    iconClass: "fas fa-check",
    text: "SBC sa Linux operativnim sistemom sa pasivnim ili aktivnim hladnjakom.",
  },
  {
    id: 6,
    iconClass: "fas fa-check",
    text: "Visokokvalitetn sjani panel sa led pozadinskim osvjetljenjem u okviru Plexiglas XT prstena.",
  },
  {
    id: 7,
    iconClass: "fas fa-check",
    text: "Integrisan VoiP ili Intecom sa Ethernet I/O interfejsom i robusnim kućištem otpornim na vanjske uslove.",
  },
];


function ConnectSection() {
  const { t } = useTranslation();

  return (
    <section
      id="parkingm"
      className="section discover-area bg-gray overflow-hidden ptb_100"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
              <img
                src="/images/connect-device-series.png"
                alt=""
                style={{ maxHeight: "32rem" }}
              />
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <a href="/parking#ps-0" className="btn btn-bordered mt-4">
                {t("Saznaj više")}
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2" style={{alignSelf: 'start'}}>
            <div className="discover-text pt-4 pt-lg-0">
              <h2 className="pb-4">{t("Connect IN/OUT")}</h2>
              <ul className="check-list">
                {discoverData.map((item, idx) => {
                  return (
                    <div key={`do_${idx}`}>
                      <li className="py-2">
                        <div className="list-box media">
                          <span className="icon align-self-center">
                            <i className={item.iconClass} />
                          </span>
                          <span className="media-body pl-2">{item.text}</span>
                        </div>
                      </li>
                    </div>
                  );
                })}
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConnectSection;
