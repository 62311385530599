import React from "react";

function PictureLeftContent({ id, label, content, image, alt }) {

  return (
    <section
      id={id}
      className="discover-area overflow-hidden ptb_100"
      style={{ padding: "30px 0" }}
    >
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="service-thumb  mx-auto mt-3 mt-lg-0">
              <img src={image} alt={alt} />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            <div className="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
              <h4 className="pb-4">{label}</h4>
              <ul className="service-list style-two">
                <div className="service-text">
                  <p>{content}</p>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PictureLeftContent;
