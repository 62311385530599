const enJSON = {
  "Parking sistemi": "Parking Systems",
  "Connect Serija": "Connect Series",
  Abacus: "Abacus",
  Slimpark: "Slimpark",
  "Abacus Serija": "Abacus Series",
  "Slimpark Serija": "Slimpark Series",
  "Naplatni uređaji": "Payment Devices",
  Detekcija: "Detection",
  Servis: "Service",
  Softver: "Software",
  "Inteligentna parking rješenja": "Intelligent Parking Solutions",
  "Pametna rješenja za menadžment individualnih parking prostora. Od klasičnih sistema sa više desetina terminala do cloud rješenja za pametno upravljanje u gradskim sistemima.":
    "Smart solutions for management of individual parking spaces. From classic systems with dozens of terminals to cloud solutions for smart management in city systems.",
  "Ultrazvučni senzor": "Ultrasonic Sensor",
  "Ultrazvučni senzor eksterni LED": "Ultrasonic Sensor External LED",
  "Dijagonalna detekcija": "Diagonal detection",
  "Senzor sa 2 kamere": "Surveillance with 2 cameras",
  "Wireless podni senzori": "Wireless floor sensors",
  "Kamere za nadzor mjesta": "Site Surveillance Cameras",
  "Kamere za indvidualnu analizu mjesta": "Cameras for individual site analysis",

  "Sistemi za detekciju slobodnih mjesta":"Vacancy Detection Systems",
  "Parkomati za otvorene parkinge":"Parking meters for open parking lots",
  "Specifična rješenja po zahtjevu":"Custom solutions upon request",
  "Sistemska rješenja":"What We Offer",
  "Sistemi za detekciju": "Detection Systems",
  Naslovna: "Home",

  "Senzor za detekciju sa integrisanim LED-om":"Detection sensor with integrated LED",
  "Senzor za detekciju sa externim LED": "Detection sensor with external LED",
  "Senzor za dijagonalnu detekciju": "Diagonal detection sensor",
  "Senzor za nadozor sa dvije kamere":"Surveillance sensor with two cameras",
  "Kamere za nadzor neoznačenih parking mjesta":"Cameras for surveillance of unmarked parking spaces",
  "Kamere za nadzor individualnih parking karakteristika":"Cameras for monitoring individual parking characteristics",

  Jezik: "Language",
  "Saznaj više": "Learn more",
  Parkomati: "Parking meters",
  "O nama": "About us",

  "Instalacija sistema": "System Installation",
  "Profesionalan pristup u projektovanju i izvođenju svake pojedinačne instalacije koje ispunjavaju sve zahtjeve naših kupaca.":
    "A professional approach in the design and execution of each individual installation that meets all the requirements of our customers.",
  "Konfiguracija zahtjeva": "Request Configuration",
  "Modularnost sistema omogućava da svaki kupac konfigurira i kreira okruženje odabirom svih funkcionalnosti koje su mu potrebne.":
    "The modularity of the system allows each customer to configure and create an environment by selecting all the functionalities they need.",
  "Podrška 0/24": "0/24 Support",
  "Naš tim nudi potpunu podršku za svaki instalirani sistem od prvog dana.":
    "Our team offers full support for every installed system from day one.",

  "Software sa više od 100 funkcionalnosti za parking operatore.":
    "Software with more than 100 functionalities for parking operators.",
  Funkcionalnosti: "Functionalities",
  Sigurnost: "Security",
  "Sigurnosni protokoli za zaštitu finansijskih podataka i komunikaciju.":
    "Security protocols for the protection of financial data and communication.",
  Notifikacije: "Notifications",
  "Notifikacije i alarmi podesivi za individualne potrebe parking sistema.":
    "Notifications and alarms adjustable for the individual needs of parking system.",
  "Lokacijski pristup": "Location Access",
  "Daljinski pristup i kontrola sistema sa bilo koje lokacije.":
    "Remote access and control of the system from any location.",
  Podešavanja: "Settings",
  "Individualna podešavanja postavki za različite potrebe korisnika.":
    "Individual settings adjustments for different user needs.",
  Cloud: "Cloud",
  "Uvezivanje više sistema u sveobuhvatno cloud rješenje upravljnja.":
    "Connecting multiple systems into a comprehensive cloud management solution.",

  Kontakt: "Contact",
  Ime: "Name",
  "Pošalji poruku": "Send Message",
  Poruka: "Message",
  Naslov: "Subject",
  "Email polje nije validno!": "Email is not valid",
  "Polje Ime ne moze biti prazno!": "Field Name cannot be empty!",
  "Polje Email ne moze biti prazno!": "Field Email cannot be empty!",
  "Polje Naslov ne moze biti prazno!": "Field Subject cannot be empty!",
  "Polje Poruka ne moze biti prazno!": "Field Message cannot be empty!",
  "Email poslan": "Email sent",
  "Hvala što ste nam se javili.": "Thank you for reaching out.",
  "Email uspješno poslan. Javit ćemo Vam se uskoro.":
    "Email successfully sent. We will contact you soon.",
  Zatvori: "Close",
  "Za više informacija i/ili pitanja možete nas kontaktirati putem forme, email-a ili telefona.":
    "For more information and/or questions, you can contact us via the form, email or phone.",
  "Sva prava zadržana": "All Rights Reserved",
};

export default enJSON;
