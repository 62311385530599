import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';

function LandingSection() {
    const { t } = useTranslation();

        return (
            <section id="home" className="section welcome-area logo-item bg-overlay overflow-hidden d-flex align-items-center">
                <Carousel  transitionTime="1200" autoPlay interval="4000"  showThumbs={false} stopOnHover={false} swipeable={false} animationHandler="fade" infiniteLoop pause={false} >
                    <div>
                        <img id="home1" alt="" src="images/home1.jpg" />
                    </div>
                    <div>
                        <img id="home2" alt="" src="images/home2.jpg" />
                    </div>
                    <div>
                        <img id="home3" alt="" src="images/home3.jpg" />
                    </div>
                    <div>
                        <img id="home4" alt="" src="images/home4.jpg" />
                    </div>
                    <div>
                        <img id="home5" alt="" src="images/home5.jpg" />
                    </div>
                    <div>
                        <img id="home6" alt="" src="images/home6.jpg" />
                    </div>
                    <div>
                        <img id="home7" alt="" src="images/home7.jpg" />
                    </div>
                </Carousel>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-7 col-lg-8">
                            <div className="welcome-intro text-center">
                                <h2 className="text-white">{t('Inteligentna parking rješenja')}</h2>
                                <p className="text-white my-4" style={{fontSize: '16px'}}>{t("Pametna rješenja za menadžment individualnih parking prostora. Od klasičnih sistema sa više desetina terminala do cloud rješenja za pametno upravljanje u gradskim sistemima.")}</p>
                                <div className="welcome-bottom">                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-bottom">
                    <svg fill="#fff" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="fill" d="M0,6V0h1000v100L0,6z" />
                    </svg>
                </div>
            </section>
        );
}

export default LandingSection;