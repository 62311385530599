import React from "react";
import PictureRightContent from "./PictureRightContent";
import PictureLeftContent from "./PictureLeftContent";
import { useTranslation } from "react-i18next";

function DetectionContent() {
  const { t } = useTranslation();

  const headingText = `Sistemi za detekciju mjesta su usko vezani za sisteme parkiranja i naplatu. Detekcija,brojanje i nadgledanje statusa svih
pojedinačnih mjesta su neizbježan sistem ukolio želite da imate potpunu kontrolu nad parking prostorom. Mi nudimo više rješenja i senzora koja sveobuhvatno ispunjavaju različite 
korisničke zahtjeve. Bilo da se radi o zatvorenim garažama ili o otvorenom parking prostoru naša rješenja 
daju najpreciznija mjerenja i najbrži odziv na tržištu sistema za detekciju mjesta.`;
  const headingTexttwo =
    "Sistemi za detekciju mjesta su usko vezani za sisteme parkiranja i naplatu.  Mi nudimo više rješenja i senzora koja sveobuhvatno ispunjavaju različite korisničke zahtjeve.";

  const dataLeft1 = {
    title: t("Senzor za detekciju sa integrisanim LED-om"),
    content:
      "Ultrasonični senzor je poznat po svom jedinstvenom dizajnu i dokazanoj pouzdanosti. Ova vrsta senzora se instalira u centralnom dijelu iznad parking mjesta. Svjetlina i boja RGB LED senzora se  upravlja preko centralnog upravljačkog softwera. Specijalna optička tehnologija omogućava vidljivost senzora iz svih smjerova kretanja.",
    image: "/images/detection/image14.jpg",
    alt: "image",
  };

  const dataLeft2 = {
    title: t("Senzor za dijagonalnu detekciju"),
    content:
      "Kao alternativa za centralni senzor iznad parking mjesta, dijagonalni senzor se instalira u prednjem dijelu iznad parking mjesta na samom ćošku, te sa te tačke posmatra dato parking mjesto. Ova vrsta senzora se koristi u slučajuvda su parking mjesta razdvojena zidovima tako da se senzor jasno može istaknuti u voznoj traci.",
    image: "/images/detection/image4.jpg",
    alt: "image",
  };

  const dataLeft3 = {
    title: t("Wireless podni senzori"),
    content:
      "Nudimo također wireless podne senzore napojene sa baterijom, koji radiofrekvencijom šalju informaciju o zauzetosti datog parking mjesta na otvorenim parking prostorima. Koristi dva metoda detekcije zauzetosti kako bi osigurali što veću preciznost mjerenja.",
    image: "/images/detection/image8.png",
    alt: "image",
  };

  const dataLeft4 = {
    title: t("Kamere za nadzor individualnih parking karakteristika"),
    content:
    "Ukolito vam je potrebna informacija o tome koliko je dugo dato parking mjesto zauzeto ili da li je na tom mjestu električna punionica za vozila sobodna ili ne, onda ovo rješenje stupa na snagu. Naš MultiGuide CamGuide software će precizno snimati  svako pojedinačno parking mjesto i spašavati statistiku korištenja istog kroz vrijeme.",
    image: "/images/detection/image2.png",
    alt: "image",
  };

  const dataRight1 = {
    title: t("Senzor za detekciju sa externim LED"),
    content:
      "Senzor sa externim LED radi kao i senzor sa integrisanim LED, no međutim LED za oznaku statusa se nalazi odvojeno od samog senzora. Ove vrste senzora se koriste kod stubova koji blokiraju vidljivost senzora samog.",
    image: "/images/detection/image24.jpg",
    alt: "image",
  };
  const dataRight2 = {
    title: t("Senzor za nadozor sa dvije kamere"),
    content:
      "Ovaj senzor fukncionira tako da nadgleda više parking mjesta sa jednog mjesta. Senzor se instalira u sredinu vozne trake, sa jednom kamerom koja nadgleda parking mjesta lijevo i sa drugom kamerom koja nadgleda parking mjesta desno. Dodatak brojanju slobodnih mjesta kod ovih senzora jeste da se pored zauzetosti šalje i informacija o tablicama u centralni upravljački software.",
    image: "/images/detection/image12.jpg",
    alt: "image",
  };
  const dataRight3 = {
    title: t("Kamere za nadzor neoznačenih parking mjesta"),
    content:
      "Rješenja sa kamerama koja koriste vještačku inteligenciju (AI) sve su više rasporostranjena. Snimci kamera se koriste da treniraju naš insteligentni softverski algoritam za prepoznavanje objekata. Ova tehnologija omogućava da se vozila prebrojavaju u otvorenim parking prostorima gdje nema jasno iscrtanih parking mjesta.",
    image: "/images/detection/image21.png",
    alt: "image",
  };

  return (
    <section className=" faq-area ptb_100" style={{ paddingTop: "2rem" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 ">
            <div
              className="section-heading text-center"
              style={{ marginBottom: 0 }}
            >
              <p className="d-none d-sm-block mt-4">{headingText}</p>
              <p className="d-block d-sm-none mt-4">{headingTexttwo}</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="faq-content">
              <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="card-body px-0 py-3">
                    <PictureLeftContent
                      id="d1"
                      label={dataLeft1.title}
                      content={dataLeft1.content}
                      image={dataLeft1.image}
                      alt={dataLeft1.alt}
                    />
                    <PictureRightContent
                      id="d2"
                      label={dataRight1.title}
                      content={dataRight1.content}
                      image={dataRight1.image}
                      alt={dataRight1.alt}
                    />
                    <PictureLeftContent
                      id="d3"
                      label={dataLeft2.title}
                      content={dataLeft2.content}
                      image={dataLeft2.image}
                      alt={dataLeft2.alt}
                    />
                    <PictureRightContent
                      id="d4"
                      label={dataRight2.title}
                      content={dataRight2.content}
                      image={dataRight2.image}
                      alt={dataRight2.alt}
                    />
                    <PictureLeftContent
                      id="d5"
                      label={dataLeft3.title}
                      content={dataLeft3.content}
                      image={dataLeft3.image}
                      alt={dataLeft3.alt}
                    />
                    <PictureRightContent
                      id="d6"
                      label={dataRight3.title}
                      content={dataRight3.content}
                      image={dataRight3.image}
                      alt={dataRight3.alt}
                    />
                    <PictureLeftContent
                      id="d7"
                      label={dataLeft4.title}
                      content={dataLeft4.content}
                      image={dataLeft4.image}
                      alt={dataLeft4.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DetectionContent;
