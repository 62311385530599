import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import internationalization from "../service/i18n";

function Header() {
  const [defaultLanguage, setDefaultLanguage] = useState("ba");
  const { t } = useTranslation();

  useEffect(() => {
    const lan = window.localStorage.getItem("lan");
    if (lan) {
      setDefaultLanguage(lan);
      internationalization.changeLanguage(window.localStorage.getItem("lan"));
    }

    return () => {};
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const { hash } = window.location;
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "instant" });
          window.scrollBy(0, -80);
        }
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (defaultLanguage) {
      window.localStorage.setItem("lan", defaultLanguage);
      internationalization.changeLanguage(defaultLanguage);
    } else {
      window.localStorage.setItem("lan", "ba");
      internationalization.changeLanguage("ba");
    }
    return () => {};
  }, [defaultLanguage]);

  // const switchLanguage = ({ lng }) => {
  //   internationalization.changeLanguage(lng);
  //   setDefaultLanguage(lng);
  // };

  // const getDefaultLanguage = () => {
  //   return `/${defaultLanguage}.svg`;
  // };

  return (
    <header
      id="header"
      className="navbar navbar-sticky navbar-expand-lg navbar-dark"
    >
      <div className="container position-relative">
        <a className="navbar-brand" href="/">
          <img
            className="navbar-brand-regular"
            src="/logo.avif"
            alt="logo"
            style={{ height: "60px" }}
          />
          <img
            className="navbar-brand-sticky"
            src="/logo.avif"
            alt="sticky logo"
            style={{ height: "50px" }}
          />
        </a>
        <div className="logo-name">Process Control</div>

        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-toggle="navbarToggler"
          aria-label="Toggle navigation"
        >
          <span id="close-button-icon" className="navbar-toggler-icon" />
        </button>
        <div className="navbar-inner" id="navbarSupportedContent">
          <button
            if="toggle-button"
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <nav>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/parking#ps"
                  id="navbarDropdownMenuLink"
                  // data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t("Parking sistemi")}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/parking#ps-0"
                    >
                      {t("Connect Serija")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/parking#ps-1"
                    >
                      {t("Abacus Serija")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/parking#ps-2"
                    >
                      {t("Slimpark Serija")}
                    </a>
                  </li>
                </ul>
              </li>

   

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/detekcija"
                  id="navbarDropdownMenuLink"
                  // data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t("Sistemi za detekciju")}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/detekcija#d1"
                    >
                      {t("Ultrazvučni senzor")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/detekcija#d2"
                    >
                      {t("Ultrazvučni senzor eksterni LED")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/detekcija#d3"
                    >
                      {t("Dijagonalna detekcija")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/detekcija#d4"
                    >
                      {t("Senzor sa 2 kamere")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/detekcija#d5"
                    >
                      {t("Wireless podni senzori")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/detekcija#d6"
                    >
                      {t("Kamere za nadzor mjesta")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item click-item"
                      href="/detekcija#d7"
                    >
                      {t("Kamere za indvidualnu analizu mjesta")}
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link click-item" href="/#payment">
                  {t("Naplatni automati")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link click-item" href="/#about">
                  {t("Software")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link click-item" href="/#service">
                  {t("Servis")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link click-item" href="/#contact">
                  {" "}
                  {t("Kontakt")}
                </a>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdownMenuLink"
                  style={{ paddingRight: 0 }}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="navbar-brand-regular"
                    src={getDefaultLanguage()}
                    alt="brand-logo"
                    style={{ height: "16px", paddingLeft: "5px" }}
                  />
                  <img
                    className="navbar-brand-sticky"
                    src={getDefaultLanguage()}
                    alt="brand-logo"
                    style={{ height: "16px", paddingLeft: "5px" }}
                  />
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{ minWidth: "1rem", width: "7rem" }}
                >
                  <li style={{ cursor: "pointer" }}>
                    <button
                      onClick={() => {
                        switchLanguage({ lng: "ba" });
                      }}
                      className="dropdown-item click-item"
                    >
                      BA{" "}
                      <img
                        src="/ba.svg"
                        alt="logo"
                        style={{ height: "20px", paddingLeft: "20px" }}
                      />
                    </button>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <button
                      onClick={() => {
                        switchLanguage({ lng: "en" });
                      }}
                      className="dropdown-item click-item"
                    >
                      EN
                      <img
                        src="/en.svg"
                        alt="logo"
                        style={{ height: "20px", paddingLeft: "25px" }}
                      />
                    </button>
                  </li>
                </ul>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
