import React from "react";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";

const iconList = [
  {
    id: 1,
    iconClass: "fas fa-phone-alt",
    text: `Tel: +387 33 711 800 `,
    text2: `Mob: +387 61 633 063 `,
  },
  {
    id: 2,
    iconClass: "fas fa-envelope",
    text: "info@processcontrol.ba",
  },
  {
    id: 3,
    iconClass: "fas fa-home",
    text: "Topal Osman paše 18A, 71000 Sarajevo",
  },
];

function ContactSection() {
  const { t } = useTranslation();
  return (
    <>
      <section id="contact" className="section contact-area bg-gray ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{t("Kontakt")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-12 col-md-5">
              <div className="contact-us">
                <p className="mb-3">
                  {t(
                    "Za više informacija i/ili pitanja možete nas kontaktirati putem forme, email-a ili telefona."
                  )}
                </p>
                <ul>
                  {iconList.map((item, idx) => {
                    return (
                      <li key={`ci_${idx}`} className="py-2">
                        <div className="media">
                          <div className="social-icon mr-3">
                            <i className={item.iconClass} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignSelf: "center",
                            }}
                          >
                            <div className="media-body ">{item.text}</div>
                            <div
                              className="media-body align-self-center"
                              style={{ marginLeft: "0rem" }}
                            >
                              {item?.text2}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 pt-4 pt-md-0">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <section className="section map-area">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              title="map"
              width="1080"
              height="519"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=71000,%20Topal%20Osman-pa%C5%A1e%2018A&t=&z=15&ie=UTF8&iwloc=&output=embed"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactSection;
