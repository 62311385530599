import React from "react";
import { useTranslation } from "react-i18next";

function SoftwareSection() {
  const { t } = useTranslation();
  const featureDataTwo = [
    {
      id: 1,
      iconClass: "flaticon-tap",
      title: t("Funkcionalnosti"),
      text: t("Software sa više od 100 funkcionalnosti za parking operatore."),
    },
    {
      id: 2,
      iconClass: "flaticon-fingerprint",
      title: t("Sigurnost"),
      text: t(
        "Sigurnosni protokoli za zaštitu finansijskih podataka i komunikaciju."
      ),
    },
    {
      id: 3,
      iconClass: "flaticon-notification",
      title: t("Notifikacije"),
      text: t(
        "Notifikacije i alarmi podesivi za individualne potrebe parking sistema."
      ),
    },
    {
      id: 4,
      iconClass: "flaticon-place",
      title: t("Lokacijski pristup"),
      text: t("Daljinski pristup i kontrola sistema sa bilo koje lokacije."),
    },
    {
      id: 5,
      iconClass: "flaticon-settings",
      title: t("Podešavanja"),
      text: t(
        "Individualna podešavanja postavki za različite potrebe korisnika."
      ),
    },
    {
      id: 6,
      iconClass: "flaticon-language",
      title: t("Cloud"),
      text: t(
        "Uvezivanje više sistema u sveobuhvatno cloud rješenje upravljanja."
      ),
    },
  ];

  return (
    <section id="about" className="section features-area bg-white ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-10">
            <div className="section-heading text-center">
              <h2>{t("Software")}</h2>
              <p className="d-none d-sm-block mt-4">
                Tržište parking menadžment sistema je zahtjevno tržište koje ima
                mnoštvo različitih zahtjeva, ali svi ti zahtjevi imaju jedno
                zajedničko, a to je da svi imaju fleksibilan set funkcija koje
                se mogu individualno prilagoditi svim klijentima. Tokom razvoja,{" "}
                <a
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                  href="https://designa.com/"
                  rel="noopener noreferrer"
                >
                  Designa
                </a>{" "}
                je razvila preko 100 softwerskih funkcionalnosti koje pokrivaju
                gotovo sve aplikativne oblasti za operatere parking sistema.
                Bilo to da su aerodromi, šoping centri, univerziteti, bolnice
                ili hoteli, svi mogu odabrati najbolje rješenje za njihove
                potrebe i individualno ih sebi prilagoditi. Godinama{" "}
                <a
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                  href="https://designa.com/"
                  rel="noopener noreferrer"
                >
                  Designa
                </a>{" "}
                unapređuje svoje funkcionalnosti i širi svoj portfolio kako bi u
                svim oblastima zadovoljila zahtjeve i potrebe operatera parking
                sistema.
              </p>
              <p className="d-block d-sm-none mt-4">
                Snaga i specijalnost naše kompanije su tehnički eksperti i
                zaposleni koji imaju duboko razumijevanje tehničko-industrijskih
                trendova i spremni su da ponude najbolje rješenja za Vaše
                poslovne potrebe.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {featureDataTwo.map((item, idx) => {
            return (
              <div key={`ft_${idx}`} className="col-12 col-md-6 col-lg-4">
                <div className="icon-box text-center p-4">
                  <div className="featured-icon mb-3">
                    <span className={item.iconClass} />
                  </div>
                  <div className="icon-text">
                    <h3 className="mb-2">{item.title}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default SoftwareSection;
