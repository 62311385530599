import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import "./Modal.css";

class Modal extends Component {
  closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      this.props.onClose();
    }
  };

  componentDidMount = () => {
    document.body.addEventListener("keydown", this.closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", this.closeOnEscapeKeyDown);
    };
  };

  render() {
    const { t } = this.props;
    return (
      <CSSTransition
        in={this.props.show}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
      >
        <div className="modal" onClick={this.props.onClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h4 className="modal-title">{this.props.title}</h4>
            </div>
            <div className="modal-body">
              <img
                src="images/mail.png"
                alt=""
                style={{ height: "8rem", padding: "1rem" }}
              />
              {this.props.children}
            </div>
            <div
              className="modal-footer"
              style={{ alignSelf: "center", paddingTop: 0 }}
            >
              <button
                className="btn btn-lg theme-color btn-block mt-3"
                style={{ width: "9rem" }}
                onClick={this.props.onClose}
              >
                {t("Zatvori")}
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default withTranslation()(Modal);
