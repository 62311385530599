import React from "react";
import { useTranslation } from 'react-i18next';

const iconList = [
  {
    id: 1,
    link: "facebook",
    href: "https://www.facebook.com/www.processcontrol.ba/",
    iconClass: "fab fa-facebook-f",
  },
  {
    id: 2,
    link: "twitter",
    href: "https://www.linkedin.com/",
    iconClass: "fab fa-linkedin",
  },
];

function FooterSection() {
    const { t } = useTranslation();
    return (
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                <div className="copyright-left">
                  © Copyrights {new Date().getFullYear()} Process Control
                </div>
                <div className="social-icons d-flex">
                  {iconList.map((item, idx) => {
                    return (
                      <a
                        key={`fi_${idx}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={item.link}
                        href={item.href}
                      >
                        <i className={item.iconClass} />
                        <i className={item.iconClass} />
                      </a>
                    );
                  })}
                </div>
                <div className="copyright-right"> {t("Sva prava zadržana")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default FooterSection;
