import React, { Component } from 'react';
import Breadcrumb from './Breadcrumb';
import DetectionContent from './DetectionContent'

class Detection extends Component {
    render() {
        return (
            <div>
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                <div className="main">
                    <Breadcrumb/>
                    <DetectionContent />
                </div>
            </div>
        );
    }
}

export default Detection;