import React from 'react';

function PictureLeftContent({img, title, items, height}) {

        return (
            <section className=" discover-area overflow-hidden ptb_100" style={{padding: "2rem 0"}}>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <div className="service-thumb discover-thumb mx-auto mt-4 mt-lg-0" style={{display: 'flex', justifyContent: 'center', height: (height ? height : "22rem")}}>
                            <img src={img} alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        <div className="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
                        <h4 className="pb-4">{title}</h4>
                        <ul className="service-list style-two">
                            {items.map((item, idx) => {
                                return(
                                    <li key={`sf_${idx}`} className="single-service py-2">
                                        <div className="service-text">
                                            <p>{item.text}</p>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
}

export default PictureLeftContent;