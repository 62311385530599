import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Modal from "./Modal/Modal";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      loading: false,
      show: false,
      fields: {},
      errors: {},
      sty: {
        display: "none",
      },
    };
    this.myForm = React.createRef();
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  submitHangler = async (event) => {
    event.preventDefault();
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {};
    this.setState({
      loading: true,
    });
    if (!fields["name"]) {
      errors["name"] = t("Polje Ime ne moze biti prazno!");
    }
    if (!fields["email"]) {
      errors["email"] = t("Polje Email ne moze biti prazno!");
    }

    if (!fields["subject"]) {
      errors["subject"] = t("Polje Naslov ne moze biti prazno!");
    }
    if (!fields["message"]) {
      errors["message"] = t("Polje Poruka ne moze biti prazno!");
    }
    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        errors["email"] = t("Email polje nije validno!");
      }
    }

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      window.Email.send({
         Host: "smtp.elasticemail.com",
        Username: "processcontrol.ba@gmail.com",
         Password: "75B073AE80C721B4CC05FB970EEE11AAC4C9",
        To: "processcontrol.ba@gmail.com",
        From: "processcontrol.ba@gmail.com",
        Subject: `Contact ${fields["name"]}(${fields["email"]}) sent a message`,
        Body: `
        <div style="padding-bottom: 8px; padding-top: 10px">Name: <b>${fields["name"]}</b></div>
        <div style="padding-bottom: 8px;">Email: ${fields["email"]}</div>
        <div style="padding-bottom: 8px;">Subject: <b>${fields["subject"]}</b></div>
        <div style="padding-bottom: 8px;">Message:</div>
        <span>${fields["message"]} </span>
        `,
      }).then(() => {
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: "",
          fields: { message: "" },
          errors: {},
          show: true,
        });

        this.myForm.current.reset();
      });
    }
    this.setState({
      loading: false,
    });
  };

  render() {
    const { t } = this.props;
    const { loading, show } = this.state;

    return (
      <div className="contact-box text-center">
        <form
          ref={this.myForm}
          onSubmit={this.submitHangler}
          className="contact-form"
          noValidate="novalidate"
          name="contactform"
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder={t("Ime")}
                  onChange={this.handleChange.bind(this, "name")}
                  value={this.state.fields["name"]}
                />
                <span
                  style={{
                    color: "red",
                    padding: "5px 0 10px 0",
                    float: "left",
                  }}
                >
                  {this.state.errors["name"]}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  onChange={this.handleChange.bind(this, "email")}
                  value={this.state.fields["email"]}
                />
                <span
                  style={{
                    color: "red",
                    padding: "5px 0 10px 0",
                    float: "left",
                  }}
                >
                  {this.state.errors["email"]}
                </span>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  placeholder={t("Naslov")}
                  onChange={this.handleChange.bind(this, "subject")}
                  value={this.state.fields["subject"]}
                />
                <span
                  style={{
                    color: "red",
                    padding: "5px 0 10px 0",
                    float: "left",
                  }}
                >
                  {this.state.errors["subject"]}
                </span>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder={t("Poruka")}
                  onChange={this.handleChange.bind(this, "message")}
                  value={this.state.fields["message"]}
                />
                <span
                  style={{
                    color: "red",
                    padding: "5px 0 10px 0",
                    float: "left",
                  }}
                >
                  {this.state.errors["message"]}
                </span>
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-lg theme-color btn-block mt-3"
              >
                <span className="text-white pr-3">
                  <i
                    className="fas fa-paper-plane"
                    style={{ visibility: "visible" }}
                  />
                </span>
                {t("Pošalji poruku")}
              </button>
            </div>
          </div>
        </form>
        <Modal
          title= {t("Email poslan")}
          onClose={() =>
            this.setState({
              show: false,
            })
          }
          show={show}
        >
          <p style={{ padding: "10px 0 0 0" }}>{t("Hvala što ste nam se javili.")}</p>
          <p style={{ padding: "0 2rem 2rem 2rem" }}>
          {t("Email uspješno poslan. Javit ćemo Vam se uskoro.")}
          </p>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(ContactForm);
