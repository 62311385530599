import React from "react";
import { useTranslation } from "react-i18next";

function ServisSection() {
  const { t } = useTranslation();

  const headingText = `Nudimo Vam širok spektar usluga dizajniranih da osiguraju konzistentan, besprekidan i dugoročan rad vaših parking sistema. 
Od profesionalnog pristupa održavanju sistema koji uključuje sve potrebne servisne intervencije na sistemu do isporuke svih rezervnih
djelova efikasno i brzo. Našim klijentima nudimo modularne servisne pakete koji se prilagođavaju njihovim potrebama, te
više nivoa održavanja koji osiguravaju da naši klijenti imaju siguran i pouzdan sistem u svojim rukama.`;

  const workData = [
    {
      id: 1,
      image: "/img/work_thumb_1.png",
      title: t("Instalacija sistema"),
      content: t(
        "Profesionalan pristup u projektovanju i izvođenju svake pojedinačne instalacije koje ispunjavaju sve zahtjeve naših kupaca."
      ),
    },
    {
      id: 2,
      image: "/img/work_thumb_2.png",
      title: t("Konfiguracija zahtjeva"),
      content: t(
        "Modularnost sistema omogućava da svaki kupac konfigurira i kreira okruženje odabirom svih funkcionalnosti koje su mu potrebne."
      ),
    },
    {
      id: 3,
      image: "/img/work_thumb_3.png",
      title: t("Podrška 0/24"),
      content: t(
        "Naš tim nudi potpunu podršku za svaki instalirani sistem od prvog dana."
      ),
    },
  ];

  return (
    <section
      id="service"
      className="section work-area bg-overlay overflow-hidden ptb_100"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="work-content text-center">
              <h2 className="text-white">{t("Servis")}</h2>
              <p className="text-white my-3 mt-sm-4 mb-sm-5">{headingText}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {workData.map((item, idx) => {
            return (
              <div key={`w_${idx}`} className="col-12 col-md-4">
                <div className="single-work text-center p-3">
                  <div className="work-icon">
                    <img className="avatar-md" src={item.image} alt="" />
                  </div>
                  <h3 className="text-white py-3">{item.title}</h3>
                  <p className="text-white">{item.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ServisSection;
