import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import LandingSection from "../shared/LandingSection";
import ConnectSection from "../shared/ConnectSection";
import AbacusSection from "../shared/AbacusSection";
import SlimparkSection from "../shared/SlimparkSection";
import PaymentDeviceSection from "../shared/PaymentDeviceSection";
import SolutionsSection from "../shared/SolutionsSection";
import DetectionSection from "../shared/DetectionSection";
import ServisSection from "../shared/ServisSection";
import SoftwareSection from "../shared/SoftwareSection";
import ContactSection from "../shared/ContactSection";

class Landing extends Component {
  render() {
    return (
      <div className="homepage-6">
        <div id="scrollUp" style={{ height: "40px" }} title="Scroll To Top">
          <HashLink
            className="nav-link "
            style={{ color: "white" }}
            smooth
            to="/#"
          >
            <i className="fas fa-arrow-up" />
          </HashLink>
        </div>
        <div className="main">
          <LandingSection />
          <SolutionsSection />
          <ConnectSection />
          <AbacusSection />
          <SlimparkSection />
          <DetectionSection />
          <PaymentDeviceSection />
          <SoftwareSection />
          <ServisSection />
          <ContactSection />
        </div>
      </div>
    );
  }
}

export default Landing;
