import React from "react";
import { useTranslation } from "react-i18next";

const discoverData = [
  {
    id: 1,
    iconClass: "fas fa-check",
    text: `LED karakterni ili TFT(10.1") display za ispis korisničkih uputa i informacija sa mnogo funkcionalnosti i opcija.`,
  },
  {
    id: 2,
    iconClass: "fas fa-check",
    text: "Barcode, QR code čitač za očitavanje kartica stalnih korisnika i termalni printer visokih performansi.",
  },
  {
    id: 3,
    iconClass: "fas fa-check",
    text: "Acess Control Prox kartice, ISO 15963 i ostali RFID sistemi sa kratkim, srednjim ili dugim dometom čitanja tokena za stalne korisnike(pretplatnike).",
  },
  {
    id: 4,
    iconClass: "fas fa-check",
    text: "LPR kamere za prepoznavanje tablica.",
  },
  {
    id: 5,
    iconClass: "fas fa-check",
    text: "TCC sa Linux operativnim sistemom sa pasivnim ili aktivnim hladnjakom.",
  },
  {
    id: 6,
    iconClass: "fas fa-check",
    text: "Najniža potrošnja energije u svojoj klasi uređaja.",
  },
  {
    id: 7,
    iconClass: "fas fa-check",
    text: "Integrisan VoiP ili Intecom sa Ethernet I/O interfejsom i robusnim kućištem otpornim na vanjske uslove.",
  },
];



function SlimparkSection() {
  const { t } = useTranslation();

  return (
    <section
      id="parkingm"
      className="section discover-area bg-gray overflow-hidden ptb_100"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
              <img
                src="/images/slimpark-device-series.png"
                alt=""
                style={{ maxHeight: "32rem" }}
              />
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
            <a href="/parking#ps-2" className="btn btn-bordered mt-4">
                {t("Saznaj više")}
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            <div className="discover-text pt-4 pt-lg-0">
              <h2 className="pb-4">{t("Slimpark IN/OUT")}</h2>
              <ul className="check-list">
                {discoverData.map((item, idx) => {
                  return (
                    <div key={`do_${idx}`}>
                      <li className="py-2">
                        {/* List Box */}
                        <div className="list-box media">
                          <span className="icon align-self-center">
                            <i className={item.iconClass} />
                          </span>
                          <span className="media-body pl-2">{item.text}</span>
                        </div>
                      </li>
                    </div>
                  );
                })}
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SlimparkSection;
