import React from "react";
import { useTranslation } from "react-i18next";

function PaymentDeviceSection() {
  const { t } = useTranslation();

  return (
    <section
      className="section service-area overflow-hidden ptb_100 bg-gray"
      id="payment"
    >
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-5 order-1 order-lg-1">
            <div className="discover-text pt-4 pt-lg-0">
              <h2 className="pb-4">{t("Naplatni automati")}</h2>
              <div>
                <a
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                  href="https://designa.com/"
                  rel="noopener noreferrer"
                >
                  Designa
                </a>{" "}
                Connect Pay naplatni automati imaju kompaktan format i moderan
                dizajn da procesiraju sve vrste naplata u vašem parking sistemu.
                Vi birate koju vrstu naplatnih uređaja želite shodno vašim
                potrebama. Da li je to naplata samo novcem ili je naplata novcem
                i kreditnim bankovnim karticama. Modularnost naših sistema
                omogućava da uz svaki automatski naplatni uređaj možete da
                birate načine vraćanja kusura vašim kupcima te da birate apoene
                koje želite da naplatni uređaj prima i vraća.
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-7 order-1 order-lg-2 d-lg-block">
            <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
              <img
                src="/images/payment-device-series.png"
                alt=""
                style={{ maxHeight: "32rem" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaymentDeviceSection;
