import React from 'react';
import { useTranslation } from 'react-i18next';

function Breadcrumb() {
    const { t } = useTranslation();

        return (
            <section className="section d-flex align-items-center" style={{ height: '450px',background: 'linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .1)), rgba(0, 0, 0, 0) url("/images/detection/image30.jpg") no-repeat scroll center center / cover'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content d-flex flex-column align-items-center text-center">
                                <h3 className="text-white">{t("Sistemi za detekciju parking mjesta")}</h3>
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a className="text-white" href="/">{t("Naslovna")}</a></li>
                                    <li className="breadcrumb-item text-white">{t("Sistemi za detekciju")}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default Breadcrumb;