import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Parking from "../shared/Parking/Parking";
import Detection from "../shared/Detection/Detection";
import Header from "../shared/Header";
import Landing from "../landing/landing";
import FooterSection from "../shared/Footer";

const privateRoutes = ["/", "/parking", "/detekcija"];

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Route path={privateRoutes} component={Header} />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/parking" component={Parking} />
            <Route path="/detekcija" component={Detection} />
          </Switch>
          <Route path={privateRoutes} component={FooterSection} />
        </Router>
      </div>
    );
  }
}
export default MyRouts;
